import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerHomeFormAdd: IRouter = {
  path: '/document/form-add',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'home.create', //translate here for breadcrumb and sidebar
  permissionCode: PermissionCode.MEDIAS_CREATE,
};
