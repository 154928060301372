import React from 'react';
import { BarChart } from 'react-feather';

import { IRouter } from '@routers/interface';

export const routerLevels: IRouter = {
  path: '/levels',
  loader: React.lazy(() => import('./index')),
  exact: true,
  icon: <BarChart />,
  widthFull: true,
  name: 'levels.name', //translate here for breadcrumb and sidebar
};
