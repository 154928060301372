export default {
  'role.name': 'Quản lý vai trò',
  'role.userManager': 'Quản lý người dùng',
  'roles.name': 'Vai trò',
  'roles.roleName': 'Tên vai trò',
  'roles.rolePermissions': 'Quyền',
  'roles.permissionCodes': 'Quyền',
  'roles.delete.title': 'Xóa vai trò',
  'roles.delete.content': 'Bạn có muốn xóa vai trò này?',
  'roles.createdAt': 'Ngày tạo',
  'roles.action': 'Thao tác',
  'Per.Users.Create': 'Thêm user',
  'Per.Users.Delete': 'Xoá user',
  'Per.Users.Edit': 'Sửa user',
  'Per.Users.Export': 'Xuất user',
  'Per.Users.View': 'Xem chi tiết user',
  'roles.create': 'Tạo vai trò',
  'roles.information': 'Thông tin vai trò',
  'roles.update': 'Chỉnh sửa vai trò',
  'Mes.Users.Role.UpdateSuccessfully': 'Cập nhật vai trò thành công',
  'Mes.Roles.Add.Successfully': 'Thêm vai trò thành công',

  'common.checkAll': 'Tất cả',
  'roles.add.name': 'Thêm vai trò',
  'roles.edit.name': 'Chỉnh sửa vai trò',
  'roles.info.name': 'Thông tin vai trò',
  'role.confirm.title.delete': 'Bạn có muốn xóa vai trò không ?',

  'permission.BatchsView': 'Xem chi tiết thông tin Quản lý đợt quay thưởng',

  'permission.CodeSubmitsExport': 'Xuất file lịch sử đổi mã',

  'permission.CodeSubmitsView': 'Xem chi tiết thông tin lịch sử đổi mã',

  'permission.LuckyDrawsRevokePrize': 'Thu hồi giải thưởng',

  'permission.LuckyDrawsSendSms': 'Gửi tin nhắn sms',

  'permission.LuckyDrawsUpdate': 'Cập nhật thông tin Rút thăm may mắn',

  'permission.LuckyDrawsView': 'Xem chi tiết thông tin Rút thăm may mắn',

  'permission.ModeSettingsCreate': 'Thêm tỷ lệ',

  'permission.ModeSettingsDelete': 'Xóa tỷ lệ',

  'permission.ModeSettingsUpdate': 'Cập nhật thông tin tỷ lệ',

  'permission.ModeSettingsView': 'Xem chi tiết thông tin tỷ lệ',

  'permission.PrizesCreate': 'Thêm giải thưởng',

  'permission.PrizesDelete': 'Xóa giải thưởng',

  'permission.PrizesUpdate': 'Cập nhật thông tin giải thưởng',

  'permission.PrizesView': 'Xem chi tiết thông tin giải thưởng',

  'permission.RedeemCodesBlock': 'Khóa mã',

  'permission.RedeemCodesImport': 'Nhập mã',

  'permission.RedeemCodesView': 'Xem chi tiết thông tin mã',

  'permission.RedeemsSpin': 'Quay thưởng',

  'permission.RolesCreate': 'Thêm vai trò',

  'permission.RolesDelete': 'Xóa vai trò',

  'permission.RolesUpdate': 'Cập nhật thông tin vai trò',

  'permission.RolesView': 'Xem chi tiết thông tin vai trò',

  'permission.RoundsCreate': 'Thêm Round',

  'permission.RoundsDelete': 'Xóa Round',

  'permission.RoundsUpdate': 'Cập nhật thông tin Round',

  'permission.RoundsView': 'Xem chi tiết thông tin Round',

  'permission.UsersCreate': 'Thêm người dùng',

  'permission.UsersDelete': 'Xóa người dùng',

  'permission.UsersUpdate': 'Cập nhật thông tin người dùng',

  'permission.UsersView': 'Xem chi tiết thông tin người dùng',

  'permission.CodeSubmitLogsView': 'Xem chi tiết thông tin Quản lý Nhật ký mã',

  'permission.TopUpsReTopUp': 'ReTopUp',

  'permission.LuckyDrawsImport': 'Nhập danh sách quay trúng thưởng',

  'permission.LuckyDrawsExport': 'Xuất danh sách quay trúng thưởng',

  'permission.CodeSubmitLogsExport': 'Xuất danh sách nhật ký mã',

  'role.Careers': 'Ngành',

  'permission.CareersCreate': 'Tạo Ngành',

  'permission.CareersDelete': 'Xóa Ngành',

  'permission.CareersSearch': 'Xem danh sách Ngành',

  'permission.CareersUpdate': 'Cập nhật Ngành',

  'permission.CareersView': 'Xem chi tiết Ngành',

  'role.Certificates': 'Chứng chỉ',

  'permission.CertificatesCreate': 'Tạo Chứng chỉ',

  'permission.CertificatesDelete': 'Xóa Chứng chỉ',

  'permission.CertificatesSearch': 'Xem danh sách Chứng chỉ',

  'permission.CertificatesUpdate': 'Cập nhật Chứng chỉ',

  'permission.CertificatesView': 'Xem chi tiết Chứng chỉ',

  'role.Clubs': 'Câu lạc Bộ',

  'permission.ClubsCreate': 'Tạo Câu lạc Bộ',

  'permission.ClubsDelete': 'Xóa Câu lạc Bộ',

  'permission.ClubsSearch': 'Xem danh sách Câu lạc Bộ',

  'permission.ClubsUpdate': 'Cập nhật Câu lạc Bộ',

  'permission.ClubsView': 'Xem chi tiết Câu lạc Bộ',

  'permission.ClubsCreateMember': 'Tạo khách hàng câu lạc bộ',

  'permission.ClubsCreatePartner': 'Tạo đối tác Câu lạc bộ',

  'permission.ClubsInvite': 'Mời khách hàng câu lạc bộ',

  'role.Contacts': 'Liên hệ',

  'permission.ContactsUpdate': 'Cập nhật Liên hệ',

  'role.Enterprises': 'Doanh nghiệp',

  'permission.EnterprisesCreate': 'Tạo Doanh nghiệp',

  'permission.EnterprisesDelete': 'Xóa Doanh nghiệp',

  'permission.EnterprisesSearch': 'Xem danh sách Doanh nghiệp',

  'permission.EnterprisesUpdate': 'Cập nhật Doanh nghiệp',

  'permission.EnterprisesView': 'Xem chi tiết Doanh nghiệp',

  'role.ExecutiveCommittees': 'Ban chấp hành',

  'permission.ExecutiveCommitteesCreate': 'Tạo Ban chấp hành',

  'permission.ExecutiveCommitteesDelete': 'Xóa Ban chấp hành',

  'permission.ExecutiveCommitteesSearch': 'Xem danh sách Ban chấp hành',

  'permission.ExecutiveCommitteesUpdate': 'Cập nhật Ban chấp hành',

  'permission.ExecutiveCommitteesView': 'Xem chi tiết Ban chấp hành',

  'role.Fees': 'Hội phí',

  'permission.FeesCreate': 'Tạo Hội phí',

  'permission.FeesDelete': 'Xóa Hội phí',

  'permission.FeesSearch': 'Xem danh sách Hội phí',

  'permission.FeesUpdate': 'Cập nhật Hội phí',

  'permission.FeesView': 'Xem chi tiết Hội phí',

  'role.Fields': 'Lĩnh vực',

  'permission.FieldsCreate': 'Tạo Lĩnh vực',

  'permission.FieldsDelete': 'Xóa Lĩnh vực',

  'permission.FieldsSearch': 'Xem danh sách Lĩnh vực',

  'permission.FieldsUpdate': 'Cập nhật Lĩnh vực',

  'permission.FieldsView': 'Xem chi tiết Lĩnh vực',

  'role.Markets': 'Thị trường',

  'permission.MarketsCreate': 'Tạo Thị trường',

  'permission.MarketsDelete': 'Xóa Thị trường',

  'permission.MarketsSearch': 'Xem danh sách Thị trường',

  'permission.MarketsUpdate': 'Cập nhật Thị trường',

  'permission.MarketsView': 'Xem chi tiết Thị trường',

  'role.Medias': 'Medias',

  'permission.MediasCreate': 'Tạo Medias',

  'permission.MediasDelete': 'Xóa Medias',

  'role.Members': 'Khách hàng',

  'permission.MembersCreate': 'Tạo Khách hàng',

  'permission.MembersDelete': 'Xóa Khách hàng',

  'permission.MembersSearch': 'Xem danh sách Khách hàng',

  'permission.MembersUpdate': 'Cập nhật Khách hàng',

  'permission.MembersView': 'Xem chi tiết Khách hàng',

  'role.Notifications': 'Thông Báo',

  'permission.NotificationsCreate': 'Tạo Thông Báo',

  'permission.NotificationsDelete': 'Xóa Thông Báo',

  'permission.NotificationsSearch': 'Xem danh sách Thông Báo',

  'permission.NotificationsUpdate': 'Cập nhật Thông Báo',

  'permission.NotificationsView': 'Xem chi tiết Thông Báo',

  'role.Organizations': 'Tổ chức',

  'permission.OrganizationsCreate': 'Tạo Tổ chức',

  'permission.OrganizationsDelete': 'Xóa Tổ chức',

  'permission.OrganizationsSearch': 'Xem danh sách Tổ chức',

  'permission.OrganizationsUpdate': 'Cập nhật Tổ chức',

  'permission.OrganizationsView': 'Xem chi tiết Tổ chức',

  'role.Partners': 'Đối tác',

  'permission.PartnersCreate': 'Tạo Đối tác',

  'permission.PartnersDelete': 'Xóa Đối tác',

  'permission.PartnersSearch': 'Xem danh sách Đối tác',

  'permission.PartnersUpdate': 'Cập nhật Đối tác',

  'permission.PartnersView': 'Xem chi tiết Đối tác',

  'role.Ranks': 'Hạng thành viên',

  'permission.RanksSearch': 'Xem danh sách Hạng thành viên',

  'permission.RanksUpdate': 'Cập nhật Hạng thành viên',

  'permission.RanksView': 'Xem chi tiết Hạng thành viên',

  'role.Roles': 'Vai trò',

  'permission.RolesExport': 'Xuất Vai trò',

  'permission.RolesSearch': 'Xem danh sách Vai trò',

  'role.Schedules': 'Lịch',

  'permission.SchedulesCancel': 'Hủy Lịch',

  'permission.SchedulesCreate': 'Tạo Lịch',

  'permission.SchedulesDelete': 'Xóa Lịch',

  'permission.SchedulesSearch': 'Xem danh sách Lịch',

  'permission.SchedulesUpdate': 'Cập nhật Lịch',

  'permission.SchedulesView': 'Xem chi tiết Lịch',

  'role.Sponsors': 'Doanh thu',

  'permission.SponsorsCreate': 'Tạo Doanh thu',

  'permission.SponsorsDelete': 'Xóa Doanh thu',

  'permission.SponsorsSearch': 'Xem danh sách Doanh thu',

  'permission.SponsorsUpdate': 'Cập nhật Doanh thu',

  'permission.SponsorsView': 'Xem chi tiết Doanh thu',

  'role.TargetCustomers': 'Khách hàng mục tiêu',

  'permission.TargetCustomersCreate': 'Tạo Khách hàng mục tiêu',

  'permission.TargetCustomersDelete': 'Xóa Khách hàng mục tiêu',

  'permission.TargetCustomersSearch': 'Xem danh sách Khách hàng mục tiêu',

  'permission.TargetCustomersUpdate': 'Cập nhật Khách hàng mục tiêu',

  'permission.TargetCustomersView': 'Xem chi tiết Khách hàng mục tiêu',

  'role.Users': 'Người dùng',

  'permission.UsersExport': 'Xuất file người dùng',

  'permission.UsersSearch': 'Xem danh sách người dùng',

  'role.Represents': 'Ban điều hành CLB',

  'permission.RepresentsCreate': 'Tạo Ban điều hành CLB ',

  'permission.RepresentsDelete': 'Xóa Ban điều hành CLB',

  'permission.RepresentsSearch': 'Xem danh sách Ban điều hành CLB',

  'permission.RepresentsUpdate': 'Cập nhật Ban điều hành CLB',

  'permission.RepresentsView': 'Xem chi tiết Ban điều hành CLB',

  'permission.ClubsDeleteMember': 'Xóa khách hàng câu lạc bộ',

  'permission.ClubsDeletePartner': 'Xóa Đối tác câu lạc bộ',

  'permission.ClubsUpdateMember': 'Cập nhật khách hàng câu lạc bộ',

  'permission.ClubsUpdatePartner': 'Cập nhật Đối tác câu lạc bộ',

  'permission.ClubsImportMember': 'Import Khách hàng',
  'permission.ClubsImportPartner': 'Import Đối tác',
  'permission.MembersImport': 'Import Khách hàng',
  'permission.ExecutiveCommitteesImport': 'Import Ban chấp hành',
  'permission.PartnersImport': 'Import Đối tác',
  'permission.RepresentsImport': 'Import Ban điều hành',

  // Activities
  'role.Activities': 'Hoạt động',
  'permission.ActivitiesCreate': 'Tạo hoạt động',
  'permission.ActivitiesCreateSub': 'Tạo hoạt động con',
  'permission.ActivitiesDelete': 'Xóa hoạt động',
  'permission.ActivitiesDeleteSub': 'Xóa hoạt động con',
  'permission.ActivitiesSearch': 'Xem danh sách hoạt động',
  'permission.ActivitiesUpdate': 'Cập nhật hoạt động',
  'permission.ActivitiesUpdateSub': 'Cập nhật hoạt động con',
  'permission.ActivitiesView': 'Xem chi tiết hoạt động',

  // Feedback
  'role.Feedbacks': 'Phản ánh kiến nghị',
  'permission.FeedbacksReply': 'Phản hồi',
  'permission.FeedbacksSearch': 'Xem danhh sách Phản ánh',
  'permission.FeedbacksView': 'Xem chi tiết Phản ánh',

  // NetRevenues
  'role.NetRevenues': 'Doanh thu',
  'permission.NetRevenuesCreate': 'Tạo Doanh thu',
  'permission.NetRevenuesDelete': 'Xóa Doanh thu',
  'permission.NetRevenuesSearch': 'Xem danh sách Doanh thu',
  'permission.NetRevenuesView': 'Xem chi tiết Doanh thu',

  // News
  'role.Documents': 'Thông tin',
  'permission.DocumentsCreate': 'Tạo Thông tin',
  'permission.DocumentsDelete': 'Xóa Thông tin',
  'permission.DocumentsSearch': 'Xem danh sách Thông tin',
  'permission.DocumentsView': 'Xem chi tiết Thông tin',
  'permission.DocumentsUpdate': 'Cập nhật Thông tin',

  // Levels
  'role.AccumulationLevels': 'Lũy kế',
  'permission.AccumulationLevelsUpdate': 'Cập nhật lũy kế hằng năm',
  'permission.AccumulationLevelsView': 'Xem chi tiết lũy kế',

  // Rank
  'role.AccumulationRanks': 'Hạng thành viên',
  'permission.AccumulationRanksSearch': 'Xem danh sách hạng thành viên',
};
