import React from 'react';
import { CheckSquare } from 'react-feather';

import { IRouter } from '@routers/interface';

export const routerDiscount: IRouter = {
  path: '/discount',
  loader: React.lazy(() => import('./index')),
  exact: true,
  icon: <CheckSquare />,
  widthFull: true,
  name: 'discount.title', //translate here for breadcrumb and sidebar
};
