import React from 'react';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerHistoryRevenues: IRouter = {
  path: '/member-and-partner/finance/:tab/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'historyRevenues.view', //translate here for breadcrumb and sidebar
  activePath: 'member-and-partner/history-revenues/:id/:tab',
  generatePath: () => 'member-and-partner/history-revenues/:id/checkPermission', //default url
  permissionCode: [PermissionCode.REVENUE_SEARCH, PermissionCode.SPONSORS_SEARCH],
};
