import React from 'react';

import { IRouter } from '@routers/interface';

export const routerActivitiesListMember: IRouter = {
  path: '/activities/:type/:id/list-member',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'activities.list-member', //translate here for breadcrumb and sidebar
  // permissionCode: PermissionCode.CLUBS_UPDATE,
};
