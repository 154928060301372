export default {
  'sponsor.name': 'Doanh thu',
  'sponsor.title': 'Danh sách doanh thu',
  'sponsor.titleDatePicker': 'Thời gian doanh thu',
  'sponsor.create': 'Thêm doanh thu',
  'sponsor.information': 'Chi tiết doanh thu',
  'sponsor.member': 'Tên hội viên',
  'sponsor.product': 'Sản phẩm doanh thu',
  'sponsor.unitPrice': 'Số tiền (VNĐ)',
  'sponsor.unit': 'Đơn vị',
  'sponsor.quantity': 'Số lượng',
  'sponsor.user': 'Người nhận',
  'sponsor.action': 'Hành động',
  'sponsor.intoMoney': 'Thành tiền (VNĐ)',
  'sponsor.sponsorDate': 'Ngày giao dịch',
  'sponsor.code': 'Mã khách hàng',
  'sponsor.emailAddress': 'Email',
  'sponsor.daypay': 'Ngày đóng',
  'sponsor.content': 'Nội dung',
  'sponsor.attach': 'Đính kèm',
  'sponsor.tagName': 'Khách hàng',
  'sponsor.programCode': 'Mã chương trình',
  'sponsor.delete.title': 'Xóa doanh thu',
  'sponsor.delete.content': 'Bạn có muốn xóa hóa đơn tài chính này?',
  'sponsor.type': 'Phân loại',
  'sponsor.type.revenue': 'Doanh thu',
  'sponsor.type.discount': 'Chiết khấu',
  'history-sponsors.total.discount': 'Tổng chiết khấu ',
};
