import enUS from 'antd/lib/locale/en_US';

import activities from './activities';
import auth from './auth';
import calendar from './calendar';
import careers from './careers';
import category from './category';
import certificates from './certificates';
import clubs from './clubs';
import common from './common';
import contacts from './contacts';
import cost from './cost';
import discount from './discount';
import enterprises from './enterprises';
import feedback from './feedback';
import fields from './fields';
import home from './home';
import levels from './levels';
import markets from './markets';
import memberAndPartner from './memberAndPartner';
import messAlert from './messAlert';
import news from './news';
import notification from './notification';
import organizations from './organizations';
import pageError from './pageError';
import profile from './profile';
import rank from './rank';
import rankMember from './rankMember';
import revenue from './revenue';
import roles from './roles';
import server from './server';
import setting from './setting';
import sponsor from './sponsor';
import targetCustomers from './targetCustomers';
import user from './user';

export default {
  ...enUS,
  ...common,
  ...server,
  ...auth,
  ...pageError,
  ...roles,
  ...user,
  ...sponsor,
  ...cost,
  ...profile,
  ...messAlert,
  ...memberAndPartner,
  ...notification,
  ...calendar,
  ...careers,
  ...certificates,
  ...markets,
  ...targetCustomers,
  ...organizations,
  ...enterprises,
  ...fields,
  ...category,
  ...setting,
  ...clubs,
  ...home,
  ...contacts,
  ...activities,
  ...feedback,
  ...rank,
  ...levels,
  ...revenue,
  ...news,
  ...discount,
  ...rankMember,
};
