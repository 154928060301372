import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerRankMember: IRouter = {
  path: '/rank-member/:tab',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'rankMember.title', //translate here for breadcrumb and sidebar
  activePath: 'rank-member',
  generatePath: () => 'rank-member/checkPermission', //default url
  icon: <Icon.BarChart2 />,
};
