export default {
  'sponsor.name': 'Revenue',
  'sponsor.title': 'List of revenue',
  'sponsor.titleDatePicker': 'Revenue time',
  'sponsor.create': 'Add revenue',
  'sponsor.information': 'Revenue details',
  'sponsor.member': 'Member name',
  'sponsor.product': 'Revenue products',
  'sponsor.unitPrice': 'Unit Price (VND)',
  'sponsor.unit': 'Unit',
  'sponsor.quantity': 'Quantity',
  'sponsor.user': 'Receiver',
  'sponsor.action': 'Act',
  'sponsor.intoMoney': 'Money (VND)',
  'sponsor.sponsorDate': 'Revenue date',
  'sponsor.code': 'Member code',
  'sponsor.emailAddress': 'Email',
  'sponsor.daypay': 'Close Day',
  'sponsor.content': 'Content',
  'sponsor.attach': 'Attach',
  'sponsor.tagName': 'Revenue',
  'sponsor.programCode': 'Program code',
  'sponsor.delete.title': 'Delete revenue',
  'sponsor.delete.content': 'Do you want to delete this revenue?',

  'sponsor.type': 'Type',
  'sponsor.type.revenue': 'Revenue',
  'sponsor.type.discount': 'Discount',
  'history-sponsors.total.discount': 'Total discount',
};
