export default {
  'contacts.name': 'Liên hệ',
  'contacts.title': 'Liên hệ',
  'contacts.switchboard': 'Tổng đài',
  'contacts.website': 'Website',
  'contacts.fanpage': 'Fanpage',
  'contacts.email': 'Email',
  'contacts.address': 'Địa chỉ',
  'contacts.longitude': 'Kinh độ',
  'contacts.latitude': 'Vĩ độ',
  'contact-page.message.email': 'Nhập email',
  'contact-page.message.switchboard': 'Số điện thoại tổng đài không đúng định dạng',
  'contact-page.message.format.switchboard': 'Số điện thoại tổng đài không đúng định dạng',
  'contact-page.message.format.email': 'Email không đúng định dạng',
  'contact-page.number.latitude': 'Không nhập quá 12 số sau dấu phẩy',
};
