export default {
  'revenue.name': 'Finance',
  'revenue.title': 'Finance management',
  'revenue.titleDatePicker': 'Finance time',
  'revenue.create': 'Create',
  'revenue.information': 'Finance details',
  'revenue.member': 'Customer name',
  'revenue.product': 'Finance products',
  'revenue.unitPrice': 'VND (VND)',
  'revenue.unit': 'Unit',
  'revenue.quantity': 'Quantity',
  'revenue.user': 'Receiver',
  'revenue.action': 'Act',
  'revenue.intoMoney': 'Money (VND)',
  'revenue.netFinanceDate': 'Date of payment',
  'revenue.code': "Customer's code",
  'revenue.emailAddress': 'email',
  'revenue.daypay': 'Close Day',
  'revenue.content': 'Content',
  'revenue.attach': 'Attach',
  'revenue.tagName': 'Client',
  'revenue.programCode': 'Program code',
  'revenue.delete.title': 'Delete finance',
  'revenue.delete.content': 'Do you want to delete this finance?',
  'revenue.type': 'Classify',
  'revenue.type.revenue': 'Expected revenue',
  'revenue.type.discount': 'Discount',
  'history-revenues.total.discount': 'Discount paid/ Total discount',
  'history-revenues.total.sponsor': 'Actual sponsor/ Accumulated sponsor',
  'history-revenues.action': 'Action',
  'revenue.netRevenueDate': 'Transaction date',
  'revenue.total': 'Total (VNĐ)',
  'revenue.auto': 'automatic',
  'revenue.type.sponsor': 'Sponsor',
  'revenue.money': 'Total money',
  'revenue.record': 'Sponsor record',
  'revenue.yes': 'Yes',
  'revenue.no': 'No',
  'revenue.status': 'Status',
  'revenue.status.param': '{status,select, 1 {Paid} 2 {Not paid}  other {Không xác định}}',
  'revenue.paid': 'Paid',
  'revenue.paid.title': 'Paid',
  'revenue.paid.content': 'Do you want to pay this?',
  'revenue.status.paid': 'Paid',
  'revenue.status.not-paid': 'Not paid',
};
