import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerNews: IRouter = {
  path: '/news',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'news.nameSidebar', //translate here for breadcrumb and sidebar
  menu: {
    icon: <Icon.Layout />,
  },
  permissionCode: PermissionCode.NEWS_SEARCH,
};

export const routerNewsForm: IRouter = {
  path: '/news/:type/:id',
  loader: React.lazy(() => import('./component/FormNews/FormNews')),
  exact: true,
  name: 'news.add', //translate here for breadcrumb and sidebar
  widthFull: true,
  permissionCode: PermissionCode.NEWS_VIEW_DETAIL_PAGE,
};

export const routerNewsFormAdd: IRouter = {
  path: '/news/:type',
  loader: React.lazy(() => import('./component/FormNews/FormNews')),
  exact: true,
  name: 'news.nameSidebar', //translate here for breadcrumb and sidebar
  widthFull: true,
  permissionCode: PermissionCode.NEWS_VIEW_DETAIL_PAGE,
};
