import React from 'react';
import * as Icon from 'react-feather';

import PermissionCode from '@modules/roles/contantsPermissions';
import { IRouter } from '@routers/interface';

export const routerMemberAndPartnerTabs: IRouter = {
  path: '/member-and-partner/:tab',
  loader: React.lazy(() => import('./index')),
  exact: true,
  menu: {
    activePath: 'member-and-partner',
    generatePath: () => 'member-and-partner/checkPermission', //default url
    icon: <Icon.Users />,
  },
  clubsRouter: false,
  name: 'memberAndPartner.name', //translate here for breadcrumb and sidebar
  permissionCode: [PermissionCode.MEMBERS_SEARCH, PermissionCode.PARTNERS_SEARCH],
};
